import { createSlice } from '@reduxjs/toolkit'

//type State = {
//  token: string | null;
//  refreshToken: string | null;
//  account: AccountResponse | null;
//};

//const initialState: State = { token: null, refreshToken: null, account: null };

// Example of the app with API/services, data fetch, received data normalization
// https://codesandbox.io/s/rtk-entities-basic-example-with-normalizr-bm3ie?file=/src/services/fakeAPI.js

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    refreshToken: null,
    account: null,
  },
  reducers: {
    setAuthTokens(state, action) {
      state.refreshToken = action.payload.refresh
      state.token = action.payload.access
    },
    setAccount(state, action) {
      state.account = action.payload
    },
    setAccountAndTokens(state, action) {
      state.refreshToken = action.payload.auth.refresh
      state.token = action.payload.auth.access
      state.account = action.payload.user
    },
    setLogout(state) {
      state.account = null
      state.refreshToken = null
      state.token = null
    },
  },
})

export default authSlice
