import React from 'react'
import { useField, useFormikContext } from 'formik'

// Utilities for assessment form wizard
const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    }
  }, {})
}

const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values)
    try {
      validationSchema.validateSync(values, { abortEarly: false })
      return {}
    } catch (error) {
      console.log('Validation Errors: ', getErrorsFromValidationError(error))
      return getErrorsFromValidationError(error)
    }
  }
}

const getRouteNameByPath = (pathname, routes) => {
  const currentRoute = routes.find(
    (route) =>
      route.path === pathname || (route.path instanceof RegExp && pathname.match(route.path)),
  )
  if (currentRoute !== undefined) return currentRoute.name
  else return ''
}

function getFormattedDate(date) {
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return month + '/' + day + '/' + year
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

function getRelativeDate(timestamp) {
  //const timestamp = date.getTime()
  const now = new Date()
  const difference = Math.round((now.getTime() - timestamp) / 1000)
  let output = ``
  if (difference < 60) {
    // Less than a minute has passed:
    output = `${difference} seconds ago`
  } else if (difference < 3600) {
    // Less than an hour has passed:
    output = `${Math.floor(difference / 60)} minutes ago`
  } else if (difference < 86400) {
    // Less than a day has passed:
    output = `${Math.floor(difference / 3600)} hours ago`
  } else if (difference < 2620800) {
    // Less than a month has passed:
    output = `${Math.floor(difference / 86400)} days ago`
  } else if (difference < 31449600) {
    // Less than a year has passed:
    output = `${Math.floor(difference / 2620800)} months ago`
  } else {
    // More than a year has passed:
    output = `${Math.floor(difference / 31449600)} years ago`
  }

  return output
}

function formatTime(date) {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes

  return `${hours}:${minutes} ${ampm}`
}

function getFormattedDateTime(date) {
  //const year = date.getFullYear()
  const month = months[date.getMonth()]
  const day = date.getDate().toString()

  return `${formatTime(date)} | ${month} ${day}`
}

const getFormattedDateTime2 = (date) => `${getFormattedDate(date)} at ${formatTime(date)} `

export {
  getRouteNameByPath,
  getFormattedDate,
  getFormattedDateTime,
  getFormattedDateTime2,
  getRelativeDate,
  validate,
}
