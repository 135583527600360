import { createSlice } from '@reduxjs/toolkit'

const siteSlice = createSlice({
  name: 'site',
  initialState: {
    sidebarShow: true,
    asideShow: false,
    sidebarUnfoldable: false,
    theme: 'default',
  },
  reducers: {
    set(state, action) {
      console.log('state -> ', state, 'action -> ', action)
      //console.log('state!!!')
      //state.sidebarShow = action.payload
      //state.sidebarUnfoldable = action.sidebarUnfoldable
      //state.asideShow = action.payload.asideShow
      if (action.payload.theme !== undefined) state.theme = action.payload.theme
      if (action.payload.sidebarUnfoldable !== undefined)
        state.sidebarUnfoldable = action.payload.sidebarUnfoldable
      if (action.payload.sidebarShow !== undefined) state.sidebarShow = action.payload.sidebarShow
      //state.push(action.payload)
    },
  },
})

export default siteSlice
